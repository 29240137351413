<template>
  <!-- <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      For a guide and recipes on how to configure / customize this project,<br>
      check out the
      <a href="https://cli.vuejs.org" target="_blank" rel="noopener">vue-cli documentation</a>.
    </p>
    <h3>Installed CLI Plugins</h3>
    <ul>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel" target="_blank" rel="noopener">babel</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li>
    </ul>
    <h3>Essential Links</h3>
    <ul>
      <li><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>
      <li><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>
      <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>
      <li><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>
      <li><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>
    </ul>
    <h3>Ecosystem</h3>
    <ul>
      <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
      <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>
      <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
      <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
    </ul>
  </div> -->
  <div>
    <head>
    <meta http-equiv="Content-Type" content="text/html; charset=iso-8859-1">
    <title>HANB Email Signature</title>
  </head>
  <body>
    <table cellpadding="0" cellspacing="0" border="0" style="font-size: 10px; font-family: 'Futura', 'Century Gothic', CenturyGothic; line-height: 14px;">
      <tbody>
        <tr>
          <td width="100%">
            <table width="700" cellpadding="0" cellspacing="0" border="0">
              <tbody>
                <tr>
                  <td width="121">
                    <a href="https://images.hanb.org/HA_email_sig_adrss">
                        <img src="../../public/HA_email_sig_adrss.png" alt="address.png" nosend="1" title="halogo" width="250" height="167" border="0">
                    </a>
                  </td>
                  <td width="750" style="color: #034584; padding-left: 20px; padding-top: 15px; padding-bottom: 10px; padding-right: 10px;">
                    <span style="color: 004578; font-size: 13px; font-family: 'Futura', 'Century Gothic', CenturyGothic; line-height: 11px;"><strong>Your Name</strong></span><br />
                    <span style="color: 766550; font-size: 11px;">Your title information here</span>
                    <br>
                    <span style="color: 766550; font-size: 11px">Your office phone number here</span>
<br>
                    <span style="color: 766550; font-size: 11px">Your email address here or other information here</span><br />
<br>
                    <a href="https://images.hanb.org/HA_email_sig_Transform">
                        <img src="../../public/HA_email_sig_Transform.png" style="PADDING-LEFT: 0px" alt="transform.jpg" nosend="1" title="trans" width="198" height="45" border="0">
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="2" height="5" style="font-size: 1px; line-height: 1px;">&nbsp;</td>
        </tr>
        <tr style="background-color: #001d51;">
          <td colspand="2" height="2"></td>
        </tr>
        <tr>
          <td style="font-size: 8px; font-family: Verdana; color: #959595; line-height: 10px; padding-bottom: 10px;" colspan="2">
            <p style="margin: 0px; padding-top: 10px;">
              The contents of this e-mail message and any attachments are confidential and are intended solely for the addressee. The information may also be legally privileged. This transmission is sent in trust, for the sole purpose of delivery to the intended recipient. If you have received this transmission in error, any use, reproduction or dissemination of this transmission is strictly prohibited. If you are not the intended recipient, please immediately
              <strong>notify</strong>
              the sender by reply e-mail or phone and
              <strong>delete</strong>
              this message and its attachments, if any.
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </body>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
